export const URL = {

    LOGIN: '/web/login',
    SNS_LOGIN: '/web/sns/login',
    MENU_LIST: '/web/menu/list',
    SITE_INFO: '/web/site/info',
    SITE_SETTINGS_INFO: '/web/site/settings/info',
    USER_INFO: '/web/user/info',
    USER_UPDATE: '/web/user/update',
    USER_UPDATE_IMAGES: '/web/user/images/update',
    USER_CREATE: '/web/user/create',
    USER_NAME_CHECK: '/web/user/userIdExist',
    EMAIL_VERIFICATION_SEND: '/web/email/verification/send',
    EMAIL_VERIFY: '/web/email/auth/verify',
    FORGOT_ID: '/web/forgot/id',
    FORGOT_PASSWORD: '/web/forgot/password',
    FORGOT_PASSWORD_CHANGE: '/web/forgot/password/change',

    PROJECT_LIST: '/web/project/list',
    PROJECT_DETAIL: '/web/project',
    PROJECT_CONTRACT_DETAIL: '/web/project/contract',
    PROJECT_CREATE: '/web/project/create',
    PROJECT_UPDATE: '/web/project/update',
    PROJECT_DELETE: '/web/project/delete',
    PROJECT_UPDATE_CONTRACT_STATUS: '/web/project/update/contract',
    PROJECT_PAYMENT_LIST: '/web/project/payment/list',
    PROJECT_PAYMENT_DETAIL: '/web/project/payment/detail',
    PROJECT_COMMENT_UPDATE: '/web/project/comment/update',
    PROJECT_COMMENT_CREATE: '/web/project/comment/create',
    PROJECT_COMMENT_DELETE: '/web/project/comment/delete',


    COMMONCODE_FILTER_LIST: '/web/codes/group',

    // NOTICE
    NOTICE_LIST: '/web/notice/list',
    NOTICE_DETAIL: '/web/notice/detail',

    QNA_LIST: '/web/qna/list',
    QNA_DETAIL: '/web/qna/detail',
    QNA_CREATE: '/web/qna/create',
    QNA_DELETE: '/web/qna/delete',

    FAQ_LIST: '/web/faq/list',
    DYNAMIC_PAGE: '/web/menu/contents',

    REVIEW_LIST: '/web/review/list',
    REVIEW_DETAIL: '/web/review/detail',
    REVIEW_CREATE: '/web/review/create',
    REVIEW_DELETE: '/web/review/delete',

    INQUIRY_CREATE: '/web/inquiry/create',

    //MAIN
    MAIN_INFO: '/web/main',
    CURRENT_TIME: '/web/current/time',

    //BANNER
    // BANNER_LIST: '/web/main'
};
