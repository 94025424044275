import React, { lazy } from 'react';
import Pages from 'store/pages';
import Loadable from 'components/Loadable';
import { CommunityApi } from "api/community/community";

const NoticeList = Loadable(lazy(() => import('./noticeList')));
const NoticeDetail = Loadable(lazy(() => import('./noticeDetail')));
const FaqList = Loadable(lazy(() => import('./faqList')));
const QnaList = Loadable(lazy(() => import('./qnaList')));
const QnaCreate = Loadable(lazy(() => import('./qnaCreate')));
const QnaDetail = Loadable(lazy(() => import('./qnaDetail')));
const ReviewList = Loadable(lazy(() => import('./reviewList')));
const ReviewDetail = Loadable(lazy(() => import('./reviewDetail')));
const ReviewCreate = Loadable(lazy(() => import('./reviewCreate')));
const NaverSuccess = Loadable(lazy(() => import('./naverSuccess.jsx')));
const NaverLogin = Loadable(lazy(() => import('./naverLogin.jsx')));
const KakaoLogin = Loadable(lazy(() => import('./kakaoLogin')));

const CommunityConfig = {
    routes: [
        {path: Pages.COMMUNITY_NOTICE, element: <NoticeList/>},
        {path: Pages.COMMUNITY_NOTICE_DETAIL, element: <NoticeDetail/>},
        {path: Pages.COMMUNITY_FAQ, element: <FaqList/>},
        {path: Pages.COMMUNITY_QNA, element: <QnaList/>},
        {path: Pages.COMMUNITY_QNA_CREATE, element: <QnaCreate/>},
        {path: Pages.COMMUNITY_QNA_DETAIL, element: <QnaDetail/>},
        {path: Pages.COMMUNITY_REVIEW, element: <ReviewList/>},
        {path: Pages.COMMUNITY_REVIEW_DETAIL, element: <ReviewDetail/>},
        {path: Pages.COMMUNITY_REVIEW_CREATE, element: <ReviewCreate/>},
        {path: Pages.NAVER_SUCCESS_URL, element: <NaverSuccess/>},
        {path: Pages.NAVER_LOGIN_URL, element: <NaverLogin/>},
        {path: Pages.KAKAO_LOGIN_URL, element: <KakaoLogin/>},
    ],
    API: CommunityApi
};

export default CommunityConfig;