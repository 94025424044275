import React, { lazy } from "react";
import Loadable from "components/Loadable";
import ProjectConfig from "views/pages/project";
const MyPage = Loadable(lazy(() => import('views/pages/mypage/mypage')));
const ProjectPayments = Loadable(lazy(() => import('views/pages/project/transaction/list')));

const AuthRoutes = [
    { path: "/mypage/info", element: <MyPage /> },
    { path: "/mypage/payments", element: <ProjectPayments /> },
    { path: "", children: [...ProjectConfig.routes] },
];

export default AuthRoutes;
