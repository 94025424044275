import React, { useEffect, useRef } from "react";
import { useGetProjectContractQuery } from "api/project/project";
import { useParams } from "react-router";
import { addComma } from "utils/functions/common";
import { SERVER_URL } from "config";
import ReactToPrint from "react-to-print";
import { Button } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";

import CloseButton from "components/buttons/CloseButton";

const MyProjectContract = () => {
    const { code } = useParams();
    const ref = useRef();
    const { data: projectContractData, isProjectLoading } = useGetProjectContractQuery(code);

    useEffect(() => {
        if (projectContractData?.content?.user && !isProjectLoading) {
            setTimeout(function () {
                window.print();
            }, 1000);
        }
    }, [projectContractData]);

    return (
        <>
            <div className="w-[800px] mx-auto mt-10 mb-2 text-end">
                <ReactToPrint
                    trigger={() => (
                        <Button variant="outlined" startIcon={<PrintIcon />} sx={{ padding: "5px 10px" }}>
                            출력하기
                        </Button>
                    )}
                    content={() => ref.current}
                />
            </div>
            <div ref={ref} className="contract_wrap w-[800px] mx-auto mb-10 m-10">
                <div className="contract_inn w-full">
                    <table className="w-full">
                        <tbody>
                            <tr>
                                <td className="bg-gray-200 text-2xl font-extrabold text-center" rowSpan={"2"}>
                                    프로젝트 용역 계약서
                                </td>
                                <td className="bg-gray-200 text-center">문 서 번 호</td>
                            </tr>
                            <tr>
                                <td className="text-center">
                                    {projectContractData?.content?.projectContractDate?.split(" ")[0].replace(/-/g, "")}-WBB-0
                                    {projectContractData?.content?.projectSeq}
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center">발 주 자</td>
                                <td>
                                    {projectContractData?.content?.companyName != null
                                        ? projectContractData?.content?.companyName
                                        : projectContractData?.content?.fullname}
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center">수 주 자</td>
                                <td>{projectContractData?.content?.siteCompanyName}</td>
                            </tr>
                            <tr>
                                <td className="text-center">계 약 명</td>
                                <td>{projectContractData?.content?.projectName}</td>
                            </tr>
                            <tr>
                                <td className="text-center">계 약 대 금</td>
                                <td>{addComma(projectContractData?.content?.projectTotalPrice)}원 VAT별도</td>
                            </tr>
                            <tr>
                                <td className="text-center">계 약 기 간</td>
                                <td>
                                    {projectContractData?.content?.projectContractDate?.split(" ")[0]} 부터{" "}
                                    {projectContractData?.content?.projectAdminEstimatedEndDate} 까지
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center">대 금 지 급</td>
                                <td>계약 후 3일 이내 100% 현금 지급</td>
                            </tr>
                            <tr>
                                <td className="text-center">무상하자기간</td>
                                <td>무상하자보증기간은 검수 완료일로부터 1년으로 한다.</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="my-2">
                        프로젝트 발주자인{" "}
                        <span className="font-bold">
                            {projectContractData?.content?.companyName != null
                                ? projectContractData?.content?.companyName
                                : projectContractData?.content?.fullname}
                        </span>
                        (이하 "甲"이라 한다)과 <span className="font-bold">{projectContractData?.content?.siteCompanyName}</span>(이하
                        "乙"이라 한다)는 민법상 계약 규정 따라 상호 신의. 성실의 원칙에 입각하여{" "}
                        <span className="font-bold">"{projectContractData?.content?.projectName}"</span> (이하‘프로젝트’라 한다)을 위하여
                        다음과 같이 계약을 체결하며, 이를 증명하기 위하여 각각 기명. 날인한 후 1부씩 보관한다.
                    </div>

                    <div className="text-center mt-5">
                        {projectContractData?.content?.projectContractDate?.slice(0, 4)}년
                        {projectContractData?.content?.projectContractDate?.slice(5, 7)}월
                        {projectContractData?.content?.projectContractDate?.slice(8, 10)}일
                    </div>

                    <table className="w-full mt-10">
                        <tbody>
                            <tr>
                                <td className="bg-gray-200 text-center w-1/2" colSpan={"2"}>
                                    "甲" (발주자)
                                </td>
                                <td className="bg-gray-200 text-center w-1/2" colSpan={"2"}>
                                    "乙" (수주자)
                                </td>
                            </tr>
                            <tr>
                                <td className="bg-gray-200 text-center min-w-[125px]">상 호</td>
                                <td>
                                    {projectContractData?.content?.companyName != null
                                        ? projectContractData?.content?.companyName
                                        : projectContractData?.content?.fullname}
                                </td>
                                <td className="bg-gray-200 text-center min-w-[125px]">상 호</td>
                                <td>{projectContractData?.content?.siteCompanyName}</td>
                            </tr>
                            <tr>
                                <td className="bg-gray-200 text-center min-w-[125px]">주 소</td>
                                <td className="whitespace-pre-line">{projectContractData?.content?.addr || "-"}</td>
                                <td className="bg-gray-200 text-center min-w-[125px]">주 소</td>
                                <td className="whitespace-pre-line">{projectContractData?.content?.siteAddr}</td>
                            </tr>
                            <tr>
                                <td className="bg-gray-200 text-center min-w-[125px]">사업자번호</td>
                                <td>{projectContractData?.content?.companyRegisterNumber || "-"}</td>
                                <td className="bg-gray-200 text-center min-w-[125px]">사업자번호</td>
                                <td>{projectContractData?.content?.siteCompanyRegisterationNum}</td>
                            </tr>
                            <tr>
                                <td className="bg-gray-200 text-center min-w-[125px]">대표이사</td>
                                <td>
                                    <div className="flex gap-10 relative">
                                        {projectContractData?.content?.companyCeoName != null
                                            ? projectContractData?.content?.companyCeoName
                                            : projectContractData?.content?.fullname}
                                        <span>(인)</span>
                                        <div className="w-[70px] h-auto absolute left-24 top-1/2 translate-y-[-50%]">
                                            {
                                                <img
                                                    src={
                                                        projectContractData?.content?.userStamp.length > 0
                                                            ? SERVER_URL + projectContractData?.content?.userStamp[0]?.url
                                                            : SERVER_URL + projectContractData?.content?.userSign[0]?.url
                                                    }
                                                    alt="itcoder"
                                                    className="w-full h-full ml-5"
                                                />
                                            }
                                        </div>
                                    </div>
                                </td>
                                <td className="bg-gray-200 text-center min-w-[125px]">대표이사</td>
                                <td>
                                    <div className="flex gap-10 relative">
                                        {projectContractData?.content?.siteCeoName} <span>(인)</span>
                                        <div className="w-[70px] h-auto absolute left-24 top-1/2 translate-y-[-50%]">
                                            <img
                                                src={SERVER_URL + projectContractData?.content?.siteStamp[0]?.url}
                                                alt="itcoder"
                                                className="w-full h-full ml-5"
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="mt-10">
                        <h1 className="text-3xl font-bold mb-6 text-center">프로젝트 용역계약 일반조건3</h1>

                        <div className="mb-4">
                            <h2 className="text-2xl font-semibold mb-2">제1조 [계약의 목적]</h2>
                            <p className="mb-2">
                                1. 본 계약은 "甲"이 진행하는 프로젝트를 성공적으로 수행하기 위하여, "甲"과 "乙"상호간의 권리와 의무를
                                규정하는데 그 목적이 있다.
                            </p>
                            <p>2. 본 계약에 따르는 지적재산권 및 프로젝트 개발에 대한 책임과 의무는 "甲"과 "乙"에 있다.</p>
                        </div>

                        <div className="mb-4">
                            <h2 className="text-2xl font-semibold mb-2">제2조 [신의, 성실 및 상호 협조]</h2>
                            <p className="mb-2">
                                "甲"과 "乙"은 상호 신의를 바탕으로 본 계약의 각 조항을 성실히 이행하여야 하며, "甲"은 본 계약 프로젝트의
                                진정성, 완전성, 적법성(불법 프로그램 개발 방지)을 보증, 이에 대한 일체의 책임을 지며, "乙"은 자신의 경력기술
                                사항이 거짓이 없음을 보증, 이에 대한 일체의 책임을 지고, 권한과 책임을 성실하게 수행한다. 또한 "甲"과 "乙"은
                                상호 요청이 있을 경우 수시로 협의하여 필요한 사항에 대하여 적극 협조한다.
                            </p>
                        </div>

                        <div className="mb-4">
                            <h2 className="text-2xl font-semibold mb-2">제3조 [개발의 범위]</h2>
                            <p className="mb-2">
                                "乙"이 "甲"에게 본 계약의 프로젝트 개발 수행기간(이하‘약정기간’이라 한다) 내 개발에 참여하게 될 프로젝트는
                                [첨부2.프로젝트 개발 명세서]와 같다. "甲"은 반드시 개발 내용을 별첨으로 첨부하여야 하며, 계약체결일까지
                                "乙"에게 고지되지 않은 프로젝트 개발 세부내용에 관하여는 "乙"에게 그 책임을 묻지 아니한다. 단, 추가적인
                                개발부분에 있어 "乙"이 서면 또는 전자서면으로 합의한 경우는 그러하지 아니하다.
                            </p>
                        </div>

                        <div className="mb-4">
                            <h2 className="text-2xl font-semibold mb-2">제4조 [프로젝트 계약 기간]</h2>
                            <p className="mb-2">1. 본 계약에 의한 프로젝트 개발 수행기간은 본 계약 제3항 기재와 같다.</p>
                            <p>
                                2. "甲"은 약정기간 경과 후 연장 개발이 필요할 경우, 연장계약 진행여부를 "甲"과 "乙"이 협의하여 연장계약 또는
                                별도의 약정을 한 후 진행할 수 있다.
                            </p>
                        </div>

                        <div className="mb-4">
                            <h2 className="text-2xl font-semibold mb-2">제5조 [개발의 납품·검수 및 하자이행·무상하자보수]</h2>
                            <p className="mb-2">
                                1. 본 계약이 기간만료, 합의 해지, 또는 당사자간의 책임 있는 사유로 인해 해지되어 종료된 경우, "乙"은
                                “용역”을 수행했던 계약 기간의 모든 자료,(계약, 개발 관련, 개발 source 포함) 문서 및 개발 시스템 운영안들을
                                "甲"에게 즉시 반환 및 전수하여 "甲" 또는 "甲"이 지정하는 제3자가 "乙"을 대신하여 “용역”을 다시 정상적으로
                                운영할 수 있도록 조치하여야 한다.
                            </p>
                            <p className="mb-2">
                                2. 본 계약의 프로젝트 성과물의 하자와 관련하여 무상하자보증 기간은 본 계약 제9항 기재와 같으며,
                                무상하자보증기간 중 하자에 대한 비용 일체는 "乙"의 부담으로 하되, 본 계약 제9항의 기간이 도과한 경우 그 비용
                                일체는 "甲"의 부담으로 한다. 단, 하자 보수는 "乙"이 프로젝트 개발에 참여한 부분에 대하여만 그 책임이 있으며,
                                그 외의 하자에 대한 책임 및 "甲"의 귀책사유로 발생된 하자의 경우에는 유상으로 한다.
                            </p>
                            <p className="mb-2">
                                3. 무상하자보증 기간 중 "甲"으로부터 하자보수의 요청을 받고 특별한 사유 없이 7일 이내에 대응하지 않는
                                경우에는 하자이행에 소요된 "甲"의 비용에 대하여 "乙"이 배상할 책임을 진다.
                            </p>
                            <p className="mb-2">
                                4. 본 계약에 의거하여 "乙"이 무상하자보증기간 동안의 무상하자보수 내용은 다음과 같으며, "甲"은 무상하자보수
                                기간 동안에 "乙"의 다른 프로젝트 참여 여부를 감안하여 일과시간 이후 및 주말 또는 유선이 가능한 경우에는
                                유선으로 한다. 무상하자보증기간 동안의 "乙"이 행하는 무상하자보수의 내용은 다음과 같다.
                            </p>
                            <p className="mb-2 ml-4">가. 본 계약에 의해 "乙"이 개발한 프로그램 소스에 오류가 발생한 경우</p>
                            <p className="ml-4">나. "甲"의 관리자의 기술적 질문에 대한 응대 (유, 무선 가능)</p>
                        </div>

                        <div className="mb-4">
                            <h2 className="text-2xl font-semibold mb-2">제6조 [프로젝트 개발 비용]</h2>
                            <p>"乙"이 "甲"의 프로젝트를 참여하고, 개발하는 대가인 프로젝트 총 대금은 본 계약 제2항에 기재된 바와 같다.</p>
                        </div>

                        <div className="mb-4">
                            <h2 className="text-2xl font-semibold mb-2">제7조 [대금 지불]</h2>
                            <p className="mb-2">
                                1. "甲"은 프로젝트 개발의 대가로 "乙"에게 정하여진 대금을 지급하고, 대금의 지급일자, 방법 및 세부내역은 본
                                계약 제4항에 기재된 바에 따른다. 대금의 지급은 원청 지급 방식을 원칙으로 하되, 별도의 약정이 있는 경우는
                                그러하지 아니하다.
                            </p>
                            <p className="mb-2">
                                2. "甲"은 본 계약 제4항에 기재된 금액을 "乙"에게 전액 지급하고, 대금의 지급은 원청으로부터 지급 받은 후 5일
                                이내로 하며, 상호 협의를 통해 지급일을 변경할 수 있다.
                            </p>
                            <p>
                                3. 상기의 프로젝트 비용에는 "乙"의 영업비, 식대, 교통비 등의 업무에 소요되는 일체의 비용이 포함되어 있음을
                                "乙"이 확인한다.
                            </p>
                        </div>

                        <div className="mb-4">
                            <h2 className="text-2xl font-semibold mb-2">제8조 [진척 상황 등의 보고]</h2>
                            <p>
                                "乙"은 본 프로젝트 개발 진척보고서를 "甲"의 프로젝트 단계별 프로세스에 의거 "甲"에게 보고하여야 하며, "甲"은
                                수시로 "乙"에게 업무진행 상황과 내용 설명을 요청할 수 있고, "乙"은 이에 응한다.
                            </p>
                        </div>

                        <div className="mb-4">
                            <h2 className="text-2xl font-semibold mb-2">제9조의1 [하도급]</h2>
                            <p className="mb-2">
                                1. "乙"은 본 계약에서 정하여진 도급업무의 일부 또는 전부를 "甲"과 사전협의를 거친 후 제3자에게 하도급 또는
                                투입인력의 변경을 할 수 있으나, "甲"에게 보고 할 의무가 있다.
                            </p>
                            <p>
                                2. "乙"이 도급업무의 일부를 제3자에게 하도급하는 경우 제3자의 귀책사유에 의한 이행지체, 불능 및 불완전이행
                                등의 계약불이행에 대한 책임은 "甲"의 관계에 있어서는 "乙"의 책임으로 한다.
                            </p>
                        </div>

                        <div className="mb-4">
                            <h2 className="text-2xl font-semibold mb-2">제9조의2 [권리 및 의무의 양도]</h2>
                            <p className="mb-2">
                                1."乙"은 본 계약으로 발생하는 권리와 의무를 "甲"의 사전 서면승인 없이 제3자에게 양도 및 승계할 수 없으며,
                                검수를 마친 계약목적물을 매각, 양도, 대여하거나 담보로 제공할 수 없다.
                            </p>
                            <p className="mb-2">
                                "乙"이 본 계약에 의한 권리 및 의무를 "甲"의 승인 하에 제3자에게 양도하는 경우 "甲"은 "乙"이 양도한 부분에
                                대한 대가를 양도받은 제3자에게 직접 지급할 수 있다.
                            </p>
                        </div>

                        <div className="mb-4">
                            <h2 className="text-2xl font-semibold mb-2">제9조의3 [위험부담 및 성과물의 관리]</h2>
                            <p className="mb-2">
                                1. "甲"이 검수 완료하기 이전에 "乙"의 고의 또는 과실에 의하여 성과물이 전부 또는 일부가 멸실, 훼손될
                                경우에는 "乙"은 원상 복구하여야 하며, 이에 대한 비용은 "乙"이 부담한다.
                            </p>
                            <p className="mb-2">
                                2. "乙"은 강제집행, 파산선고신청, 회사정리의 신청, 가압류, 가처분 등과 같은 사유의 발생으로 성과물에 대하여
                                "甲"의 소유권 보전에 영향을 미칠 우려가 있는 경우, 즉시 그 사실을 "甲"에게 통지하는 동시에 성과물의 보관
                                장소를 이전 및 조기 검수시행 등의 방법으로 "甲"의 소유권이 침해되지 아니하도록 필요한 조치를 하여야 한다.
                            </p>
                            <p className="mb-2">
                                "甲"은 검수 완료한 성과물에 대해서는 선량한 관리자의 의무를 가지고 관리하여야 하며, 이를 나태하여 성과물에
                                중대한 하자가 발생하였을 경우에는 그에 대한 책임을 진다.
                            </p>
                        </div>

                        <div className="mb-4">
                            <h2 className="text-2xl font-semibold mb-2">제10조 [지체상금]</h2>
                            <p className="mb-2">
                                1. "乙"의 고의 및 과실로 계약서 상 프로젝트 진행이 어려울 경우에는 지체일수에 따른 지체상금을 "甲"에게
                                현금으로 지급하거나, "甲"이 "乙"에게 지급할 프로젝트 대금에서 공제할 수 있다.
                            </p>
                            <p className="mb-2">
                                2. "乙"의 납기 지체로 인한 납기 지체상금율은 납기 지체 1일당 총 프로젝트 대금(VAT포함)의 1000 분의 2.5로
                                한다.
                            </p>
                            <p className="mb-2">3. "乙"은 다음의 사유에 의하여 납기가 지연되는 경우 지체상금을 면제할 수 있다.</p>
                            <p className="mb-2">가. 천재. 지변 등 기타 불가항력적인 사유로 인한 경우</p>
                            <p className="mb-2">나. "甲"의 귀책사유로 인하여 지연되었을 경우</p>
                            <p className="mb-2">다. 기타 "乙"의 귀책사유가 아님이 명백한 경우</p>
                            <p className="mb-2">라. "甲"이 납기 지연 사유에 대하여 인정하여 "甲"과 "乙" 사이에 서면 합의가 있는 경우</p>
                        </div>

                        <div className="mb-4">
                            <h2 className="text-2xl font-semibold mb-2">제11조 [시정요구. 계약해지]</h2>
                            <p className="mb-2">
                                1. "甲" 또는 "乙"은 다음 각 호의 경우 상대방에게 그 시정 또는 계약의 해지를 요청할 수 있다.
                            </p>
                            <p className="mb-2">
                                가. 본 계약 체결 후 상당한 기간이 경과하였음에도 "乙"의 개발에 진전이 없거나 지나치게 업무가 지연되어
                                프로젝트 개발 업무를 게을리 한다고 판단되어질 때
                            </p>
                            <p className="mb-2">
                                나. "乙"이 프로젝트 개발에 필요한 지원을 "甲"에게 요청하였으나 "甲"이 그 지원을 하지 않아 본 계약 프로젝트
                                개발을 기간 내에 이행할 수 없다고 판단되어질 때
                            </p>
                            <p className="mb-2">다. 정당한 사유 없이 본 계약을 위반하거나 그 이행을 해태한 때</p>
                            <p className="mb-2">라. "甲"이 프로젝트 대금의 지급을 20일 이상 지연할 때</p>
                            <p className="mb-2">
                                2. "甲" 또는 "乙"은 다음 각 호에 해당되는 경우 상대방에 대한 최고 없이 즉시 본 계약의 일부 또는 전부를
                                해지할 수 있다.
                            </p>
                            <p className="mb-2">
                                가. 가압류, 가처분, 압류, 경매, 체납처분, 기타 강제집행처분을 받았거나 파산, 회사정리법상의 보전처분,
                                회사정리, 화의 등의 절차가 진행되거나 진행될 염려가 있다고 인정될 때
                            </p>
                            <p className="mb-2">
                                나. 해산이나 영업의 전부 또는 중요한 일부를 제3자에게 양도하여 프로젝트 개발을 수행할 수 없을 때
                            </p>
                            <p className="mb-2">다. 본 계약 조건을 위반하여 전1항의 시정 요구를 받고도 3일 이내에 시정하지 않을 때</p>
                            <p className="mb-2">라. 기타 중요한 사유가 발생하여 프로젝트 개발 계약의 이행이 불가능하다고 판단하였을 때</p>
                            <p className="mb-2">
                                3. "甲"의 귀책사유로 인한 계약해지 시에는 "乙"은 "甲"으로부터 제공받은 일체의 문서 및 기타 자료의 원본 및
                                사본에 대한 반환과 "乙"이 개발한 프로그램 소스파일 등에 대한 제공을 해야 하고, "甲"은 해지일 까지 발생한
                                "乙"에 대한 지급 의무를 이행하여야 한다. 단, "乙"이 소스파일 등에 대한 제공을 거절할 경우, "甲"은 프로젝트
                                대금의 지급의무가 면제된다.
                            </p>
                            <p className="mb-2">
                                4. "乙"이 중도포기 등의 귀책사유로 인한 계약해지 시에는 7일 이내에 "乙"은 "甲"에게 그 당시까지 진행된
                                결과물을 제출해야 하며, 대체인력이 투입될 때까지 프로젝트 개발에 대한 인수인계준비 및 마무리 개발 작업을
                                완료하여야하고, 대체인력 투입 시에는 그에 따른 업무 인수인계 기간은 3일 이내로 하며 업무인수인계에 대한 비용
                                부담은 "乙"에게 있다.
                            </p>
                            <p className="mb-2">
                                5. "乙"이 전4항의 인수인계 절차를 준수한 경우에는 "甲"은 "乙"에게 프로젝트 진척 상황의 정도에 따른 프로젝트
                                비용을 지급하여야 하며, "乙"이 전4항의 인수인계 절차를 준수하지 않을 시, 계약해지 시까지의 프로젝트 대금을
                                포기하는 것으로 간주한다. 단, 천재지변 등의 불가항력적인 사유에 의하거나 일신상의 중대한 사유로 인수인계
                                절차를 수행할 수 없을 경우 및 "甲"이 "乙"의 사정을 감안하여 서면으로 확인한 경우 또는 "甲"이 "乙"의
                                인수인계가 불필요함을 서면으로 통지한 때에는 그러하지 아니한다.
                            </p>
                        </div>

                        <div className="mb-4">
                            <h2 className="text-2xl font-semibold mb-2">제12조 [손해 배상]</h2>
                            <p className="mb-2">
                                1. "甲"의 귀책사유로 인하여 본 계약이 해지될 경우 "甲"은 잔여기간 프로젝트 비용의 50%에 해당하는 금액을
                                기회비용으로 "乙"에게 지급한다.
                            </p>
                            <p className="mb-2">
                                2. "乙"의 중도포기 등의 일방적인 귀책사유로 인하여 계약이 해지될 경우 "乙"은 잔여기간 프로젝트 비용의 50%에
                                해당하는 금액을 계약불이행에 따른 손해배상금으로 "甲"에게 지급한다.
                            </p>
                            <p className="mb-2">3. 다음의 경우에는 전1항 및 전2항에서 규정한 손해배상금 지급의무를 면제하기로 한다.</p>
                            <p className="mb-2">
                                가. "甲"의 원청의 프로젝트 중지로 인하여 부득이하게 "甲"이 의뢰한 프로젝트를 진행할 수 없음을 증명할 수 있는
                                자료를 "乙"이 확인한 경우
                            </p>
                            <p className="mb-2">
                                나. 본 계약의 해지가 "乙"이 대항할 수 없는 천재지변이나 재난 또는 관계 법령 등 부득이한 사정에 기인한 경우
                            </p>
                            <p className="mb-2">
                                다. 기타 상관례 상 "甲" 또는 "乙"의 귀책사유가 아님이 명백하여 상호간에 그 귀책사유 없음을 인정하거나
                                서면으로 합의한 경우
                            </p>
                            <p className="mb-2">
                                4. 전1항 내지 전3항의 조항의 내용에 대하여 "甲"과 "乙"이 서면으로 기명. 날인한 별도의 합의서가 있을 경우 그
                                합의서가 우선하여 적용되며, 손해배상금은 본 계약에 따른 프로젝트 대금과 상계할 수 있다.
                            </p>
                            <p className="mb-2">
                                5. "乙"은 본 계약 일반조건 제5조, 제6조, 제7조에 해당하는 이행보증보험증권 또는 이행각서의 제출을 면제
                                받았다고 하여, 각 이행보증의 책임을 면하는 것은 아니며, 동 조항들에 위배하여 해당사항이 이행되지 않을 경우,
                                제5조제1항, 제6조, 제7조제3항에 기재된 금액에 범위 내에서 "甲"에 대하여 그 변제책임을 진다.
                            </p>
                        </div>
                        <div className="mb-4">
                            <h2 className="text-2xl font-semibold mb-2">제13조 [성과물의 귀속 및 지적재산권]</h2>
                            <p className="mb-2">
                                1. 본 계약 수해 중 또는 본 계약 수행과 관련하여 개발된 시스템(H/W, S/W) 및 이와 관련되어 내재된 설계, 규격,
                                자료, 기술, Know-how, 특허권, 실용신안 등 기타 모든 지적재산권의 소유권, 저작권 및 사용권은 “상위 계약”에
                                따라 "甲"에 귀속된다. 단 "乙"이 보유한 패키지 형태의 솔루션을 납품한 경우는 예외로 한다.
                            </p>
                            <p className="mb-2">
                                2. 계약해지의 경우에는 정산완료 시점까지 개발한 산출물 및 이와 관련하여 발생한 모든 "지적재산권"은 제2항의
                                규정에 의하여 정산완료 시점까지 개발한 산출물에 대한 비용 지급 후에 "甲"에 귀속된다.
                            </p>
                            <p className="mb-2">3. 각 당사자는 상대방의 사전 서면동의 없이 상대방의 상호, 상표를 사용할 수 없다.</p>
                            <p className="mb-2">
                                4. "乙"은 성과물과 관련하여 제3자의 특허권, 상표권, 의장권, 저작권, 실용신안권, 프로그램저작권, 영업비밀을
                                포함한 일체의 권리를 침해하지 않는다는 것을 보증하여야 하며, 본 계약의 이행을 위하여 제3자의 소프트웨어 등을
                                사용할 경우 "乙"은 그 취급과 관련한 라이센스를 "甲"의 비용으로 해결하여야 한다. 이때 "乙"의 성과물과
                                관련하여 제3자가 권리주장을 하거나 특허, 저작권 또는 지적소유권의 침해 등을 이유로 분쟁이나 소송이 발생할
                                경우 "乙"은 "乙"의 비용과 책임으로 해결하여야 하며, "甲"에게 손해가 있을 경우 그 손해를 배상하여야 한다.
                            </p>
                            <p className="mb-2">
                                5. "乙"은 본 계약을 통하여 발생된 결과물이 개발경력에 해당되므로 영업활동에 경력사항으로 활용할 수 있다.
                            </p>
                        </div>
                        <div className="mb-4">
                            <h2 className="text-2xl font-semibold mb-2">제14조 [프로젝트 개발 계약 기간 중 규정사항(준수사항)]</h2>
                            <p className="mb-2">"乙"은 개발 기간 중 비상주를 기본으로 한다.</p>
                        </div>
                        <div className="mb-4">
                            <h2 className="text-2xl font-semibold mb-2">제15조 [별도 약정 및 협의]</h2>
                            <p className="mb-2">1. 본 계약과 관련하여 별도 약정된 내용은 본 계약의 일부로 간주한다.</p>
                            <p className="mb-2">
                                2. 본 계약에 정하지 아니한 사항이나 본 계약에 규정된 계약조항의 변경이 있을 경우 "甲"과 "乙". 은 관련 법규,
                                상관습에 의거하여 상호 협의하여 별도 약정서를 작성하여야 하며, 추가로 작성된 별도 약정서와 본 계약의 내용이
                                상충하는 부분에 있어서는 별도약정서가 우선하여 적용된다.
                            </p>
                        </div>
                        <div className="mb-4">
                            <h2 className="text-2xl font-semibold mb-2">제16조 [합의관할]</h2>
                            <p className="mb-2">
                                본 계약의 내용 및 해석에 있어서 분쟁이 발생할 경우 및 지적재산권 및 프로젝트 개발 성과물에 대한 분쟁이
                                발생할 경우에는 서울중앙지방법원을 합의관할로 한다.
                            </p>
                        </div>
                        <div className="mb-4">
                            <h2 className="text-2xl font-semibold mb-2">제17조 [계약서 보관]</h2>
                            <p className="mb-2">
                                본 계약의 성립을 증명하기 위하여 본 계약서 2통을 작성, 기명날인한 후 "甲"과 "乙"이 각각 1통 씩 보관한다.
                            </p>
                        </div>
                        <div className="mb-4">
                            <h2 className="text-2xl font-semibold mb-2">제18조 [특이 사항]</h2>
                            <p className="mb-2">
                                1. “甲”의 요구 사항에 의해 상용 솔루션을 도입해야 하는 경우 구매 비용은 “甲”이 부담한다. 2. 업무 분석을 통해
                                “甲”에 의해 최종 승인된 기획서의 기능을 벗어난 추가적인 개발 요청 시 “을”은 “갑”에게 추가 견적을 요청하여
                                진행한다.
                            </p>
                        </div>
                        <div className="mb-4">
                            <CloseButton onClick={() => window.close()} text={"닫다"} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MyProjectContract;
