import { createApi } from '@reduxjs/toolkit/query/react';
import { Api } from '../index';
import { URL } from 'store/urls';

export const ProjectPaymentApi = createApi({
    reducerPath: 'ProjectPaymentApi',
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ['ProjectPayment'],
    endpoints: (build) => ({
        getProjectPayments: build.query({
            query: (params) => `${URL.PROJECT_PAYMENT_LIST}?${params}`,
            providesTags: ['PAYMENT_LIST']
        }),
        getProjectPayment: build.query({
            query: (params) => `${URL.PROJECT_PAYMENT_DETAIL}/${params}`,
            providesTags: ['PAYMENT_DETAIL']
        })
    })
});

export const { useGetProjectPaymentsQuery, useGetProjectPaymentQuery } = ProjectPaymentApi;
