import { createApi } from "@reduxjs/toolkit/query/react";
import { Api } from "../index";
import { URL } from "store/urls";

export const ProjectApi = createApi({
    reducerPath: "ProjectApi",
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ["Project"],
    endpoints: (build) => ({
        getProjects: build.query({
            query: (params) => `${URL.PROJECT_LIST}?${params}`,
            providesTags: ["PROJECT_LIST"]
        }),
        getProject: build.query({
            query: (params) => `${URL.PROJECT_DETAIL}/${params}`,
            providesTags: ["PROJECT_DETAIL"]
        }),
        getProjectContract: build.query({
            query: (params) => `${URL.PROJECT_CONTRACT_DETAIL}/${params}`,
            providesTags: ["PROJECT_CONTRACT_DETAIL"]
        }),
        createProject: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_CREATE}`,
                method: "POST",
                body: body
            })
        }),
        updateProject: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_UPDATE}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ['PROJECT_DETAIL', 'PROJECT_LIST']
        }),
        deleteProject: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_DELETE}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ['PROJECT_DETAIL', 'PROJECT_LIST']
        }),
        updateProjectContractStatus: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_UPDATE_CONTRACT_STATUS}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ['PROJECT_DETAIL', 'PROJECT_LIST']
        }),
        updateProjectComment: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_COMMENT_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['PROJECT_DETAIL']
        }),
        createProjectComment: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_COMMENT_CREATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['PROJECT_DETAIL']
        }),
        deleteProjectComment: build.mutation({
            query: (body) => ({
                url: `${URL.PROJECT_COMMENT_DELETE}/${body}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['PROJECT_DETAIL']
        }),
    })
});

export const { useGetProjectsQuery,
    useGetProjectQuery,
    useGetProjectContractQuery,
    useCreateProjectMutation,
    useUpdateProjectMutation,
    useDeleteProjectMutation,
    useUpdateProjectContractStatusMutation,
    useUpdateProjectCommentMutation,
    useCreateProjectCommentMutation,
    useDeleteProjectCommentMutation,
} = ProjectApi;
