import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className='w-full min-h-full flex flex-col column justify-center items-center' style={{ minHeight: "65vh" }}>
            <div >
                <h1 className="text-5xl font-semibold tracking-tighter sm:text-9xl">4<span className="">😥</span>4</h1>
            </div>
            <p className='mt-8 font-light text-lg sm:text-3xl '>OOPS! THE PAGE CAN'T BE FOUND</p>
            <Link to="/" className='mt-5 bg-black text-pink-50 py-2 px-5 rounded-full'>Go back to Home</Link>
        </div>
    );
};

export default NotFound;