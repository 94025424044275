import React, { useState, lazy, useEffect } from "react";
import Loader from "components/Loader";
import Loadable from "components/Loadable";

import { ThemeProvider } from "@mui/material/styles";
import { Container, Grid } from "@mui/material";
import "./index.css";
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import themes from "themes";
import Routes from "routes";
import { useGetSiteInfoQuery } from "api/main/main";
import { StyleSheetManager } from "styled-components";
import isPropValid from "@emotion/is-prop-valid";
import { SERVER_URL } from "config";
import { useLocation } from "react-router";
const Header = Loadable(lazy(() => import("views/layout/header")));
const Footer = Loadable(lazy(() => import("views/layout/footer")));
const SubMenu = Loadable(lazy(() => import("views/layout/subMenu")));

const noMenuArr = ["/login", "/join", "/register", "/join/complete", "/mypage/project/contract/print", "/mypage/project/items/print"];
let hasSubMenu = true;

function App() {
    const location = useLocation();
    const { data: siteInfo, loading } = useGetSiteInfoQuery();
    const [isLoading, setIsLoading] = useState(false);
    const regex = /\/(\d+)$/;

    let path = location.pathname.replace(regex, "");
    if (noMenuArr.includes(path) || path == "/") {
        hasSubMenu = false;
    } else {
        hasSubMenu = true;
    }

    useEffect(() => {
        if (typeof window !== 'undefined' && window.wcs) {
            if(!window.wcs_add) window.wcs_add = {};
            window.wcs_add["wa"] = process.env.REACT_APP_NAVER_ANALYTICS_ID;
            window.wcs.inflow();
            window.wcs_do();
        }

        if (siteInfo) {
            let favIcon = siteInfo?.content?.atchFav?.url;
            document.title = siteInfo?.content?.siteNm;

            if (favIcon) {
                let favLink = document.createElement("link");
                let head = document.getElementsByTagName("head")[0];
                favLink.id = "favicon";
                favLink.type = "image/x-icon";
                favLink.rel = "icon";
                favLink.href = SERVER_URL + favIcon;
                head.appendChild(favLink);
            }
        }
    }, [siteInfo, location]);

    const screenWidth = window.innerWidth;

    return (
        <StyleSheetManager shouldForwardProp={isPropValid} disableVendorPrefixes={false}>
            <ThemeProvider theme={themes()}>
                {isLoading && <Loader />}
                <Header />
                {hasSubMenu ? (
                    <Container>
                        <Grid container sx={{ minHeight: screenWidth < 600 ? "650px" : "850px", display: "flex" }}>
                            <Grid item sx={{
                                    width: "20%",
                                    "@media (max-width:757px)": {
                                        display: "none"
                                    }
                                }}
                            >
                                <SubMenu pathname={location.pathname} />
                            </Grid>
                            <Grid item sx={{
                                width: "80%",
                                marginBottom: "30px",
                                "@media (max-width:757px)": {
                                    width: "100%",
                                }}}>
                                <Routes />
                            </Grid>
                        </Grid>
                    </Container>
                ) : (
                    <Routes />
                )}
                <Footer data={siteInfo?.content} />
            </ThemeProvider>
        </StyleSheetManager>
    );
}

export default App;
