import {
    MainConfig,
    AuthConfig,
    ProjectPaymentConfig,
    CommunityConfig,
    ProjectConfig
} from 'views/pages';
import { CommonCodeFilterApi } from 'api/commonCode';
import { BannerApi } from 'api/main/banner';

// ==============================|| COMBINE REDUCER ||============================== //

const Reducer = {
    [MainConfig.API.reducerPath]: MainConfig.API.reducer,
    [AuthConfig.API.reducerPath]: AuthConfig.API.reducer,
    [CommunityConfig.API.reducerPath]: CommunityConfig.API.reducer,
    [ProjectConfig.API.reducerPath]: ProjectConfig.API.reducer,
    [ProjectPaymentConfig.API.reducerPath]: ProjectPaymentConfig.API.reducer,
    [CommonCodeFilterApi.reducerPath]: CommonCodeFilterApi.reducer,
    [BannerApi.reducerPath]: BannerApi.reducer
};

export default Reducer;
