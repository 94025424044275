export const ButtonStyle = {
    cusBlackButton: {
        borderRadius: '0',
        background: "#333333",
        color: "#fff",
        width: "100px",
        borderColor: "#333333",
        float: "right",
        '&:hover': {
            background: "#333333",
            color: "#fff",
            borderColor: "#333333",
        }
    },
    cusRedButton: {
        borderRadius: '0',
        background: "#f53334",
        color: "#fff",
        width: "100px",
        borderColor: "#f53334",
        float: "right",
        '&:hover': {
            background: "#f53334",
            color: "#fff",
            borderColor: "#f53334",
        }
    },
    cusBlueButton: {
        borderRadius: '0',
        background: "#2196F3",
        color: "#fff",
        width: "100px",
        borderColor: "#2196F3",
        float: "right",
        '&:hover': {
            background: "#2196F3",
            color: "#fff",
            borderColor: "#2196F3",
        }
    },
    saveBtn: {
        borderRadius: '0',
        background: "#333333",
        color: "#fff",
        borderColor: "#333333",
        width: "100px",
        '&:hover': {
            background: "#333333",
            color: "#fff",
            borderColor: "#333333",
        }
    },
    closeBtn: {
        borderRadius: '0',
        background: "#fff",
        color: "#000",
        borderColor: "rgba(51,51,51,0.72)",
        width: "100px",
        '&:hover': {
            background: "#fff",
            color: "#000",
            borderColor: "rgba(51,51,51,0.72)",
        }
    },
    fileUploadBtn: {
        color: '#00C3F9',
        padding: "5px 20px",
        borderColor: '#00C3F9',
        '&:hover': {
            background: '#00C3F9',
            color: '#fff',
            borderColor: '#00C3F9'
        }
    },
    searchButton: {
        borderRadius: '0',
        background: "#e3e3e3",
        color: "#fff",
        width: '25px',
        borderColor: "rgb(239, 240, 240)",
        '&:hover': {
            background: "rgb(239, 240, 240)",
            color: "#fff",
            borderColor: "rgb(239, 240, 240)",
        }
    }
};
