import { styled } from "@mui/system";
import { Button as BaseButton } from "@mui/material";
import React from "react";

const StyledButton = styled(BaseButton)({
    textTransform: "uppercase",
    fontWeight: "light",
    padding: "0",
    backgroundColor: "#333333",
    borderRadius: "0px",
    color: "#fff",
    borderColor: "#333333",
    '&:hover': {
        background: '#333333',
        color: '#fff',
        borderColor: '#333333',
    }
});

const Button = ({ size, ...props }) => {
    const sizes = {
        xs: {width: '100%', height: '40px'},
        sm: { width: "135px", height: "30px", fontSize: "12px" },
        md: { width: "100px", height: "40px" },
        lg: { width: "", height: "" },
        xl: { width: "170px", height: "30px" }
    };

    const sx = { width: "100px", height: "40px" };

    if (sizes[size]) {
        sx.width = sizes[size].width;
        sx.height = sizes[size].height;
        sx.fontSize = sizes[size].fontSize;
    }

    return <StyledButton color="primary" variant="outlined" sx={sx} disableElevation {...props} />;
};

export default Button;
