import React, { lazy } from 'react';
import Loadable from 'components/Loadable';
import { ProjectPaymentApi } from 'api/project/projectPayment';

const TransactionList = Loadable(lazy(() => import('./list')));

const ProjectPaymentConfig = {
    routes: [
        {
            path: '',
            children: [
                {
                    path: '/mypage/payments',
                    element: <TransactionList/>
                }
            ]
        }
    ],
    API: ProjectPaymentApi
};

export default ProjectPaymentConfig;