const Pages = {
    //COMMON
    MAIN: '/', //메인페이지   

    LOGIN: '/login', //로그인
    LOGOUT: '/logout',
    JOIN: "/join",
    JOIN_COMPLETE: "/join/complete",
    REGISTER: "/register",
    FIND_ID: "/find/id",
    FIND_PASSWORD: "/find/password",

    ERROR: '/error', //로그인
    ABOUT: '/about',

    // COSTUMER SERVICE
    COMMUNITY_NOTICE: '/community/notice',
    COMMUNITY_NOTICE_DETAIL: '/community/notice/:code',

    COMMUNITY_FAQ: '/community/faq',

    COMMUNITY_QNA: '/community/qna',
    COMMUNITY_QNA_CREATE: '/community/qna/create',
    COMMUNITY_QNA_DETAIL: '/community/qna/:code',

    COMMUNITY_REVIEW: '/community/review',
    COMMUNITY_REVIEW_DETAIL: '/community/review/:code',
    COMMUNITY_REVIEW_CREATE: '/community/review/create',

    MY_PROJECT: "/mypage/projects",
    MY_PROJECT_VIEW: "/mypage/project/:code",
    PROJECT_CREATE: "/project/create",
    MY_PROJECT_CONTRACT : "/mypage/project/contract/:code",
    MY_PROJECT_CONTRACT_PRINT: "/mypage/project/contract/print/:code",
    MY_PROJECT_ITEMS_PRINT: "/mypage/project/items/print/:code",
    NAVER_SUCCESS_URL : "/naverlogincallback",
    NAVER_LOGIN_URL : "/naverlogin",
    KAKAO_LOGIN_URL : "/kakaologincallback"
};

export default Pages;