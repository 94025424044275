/**
 * COMMON FUNCTIONS
 */
import receipt from "assets/images/icon/1._receipt.png";
import estimate from "assets/images/icon/2._estimate.png";
import contract from "assets/images/icon/3._contract.png";
import progressing from "assets/images/icon/4._progressing.png";
import complete from "assets/images/icon/5._complete.png";

export function snsDataValidate(type, data) {
    let validData = null;

    switch (type) {
        case "google":
            if (data) {
                validData = { email: data.email, fullname: data.name, snsPicture: data.picture };
            }
            console.log(validData);
            break;
        case "facebook":
            if (data) {
                validData = {
                    email: data.email != null ? data.email : data.id,
                    fullname: data.last_name,
                    snsPicture: data.picture?.data?.url
                };
            }
            //console.log(validData);
            break;
        case "naver":
            if(data && data.email) {
                validData = validData = { email: data.email, fullname: (data.name) ? data.name : data.nickname };
            }
            console.log(validData);
            break;
        case "kakao":
            if (data && data.kakao_account.is_email_verified) {
                validData = { email: data.kakao_account?.email, fullname: data.kakao_account.profile.nickname };
            }
            console.log(validData);
            break;
        default:
            validData = null;
            break;
    }

    return validData;
}

var loggedInUser = "";
export var userToken = ""; //  token for logged in user

//  setting loggedin user
export function setLoggedInUser(userData) {
    loggedInUser = userData;
}

//  setting userToken
export function setUserToken(newToken) {
    userToken = newToken;
}

export function getLoggedInUser() {
    return loggedInUser;
}

//  getting translated text
export function getTranslatedText(text) {
    return text;
}

export function getCurrentUrl() {
    return window.location.pathname;
}

//@author: Dral
//get corresponding name
export function findCorName(id, column, list, corr) {
    if (list && list.length > 0) {
        const item = list.find((item) => {
            if (corr) {
                return id == item[corr];
            }
            return id == item.code;
        });
        return item ? item[column] : "no item";
    }
    return "";
}

//@author: Dral
//get calculated seq
export const calcSeq = (data, index) => {
    if (data?.totalElements) {
        return +data?.totalElements - +data?.number - index;
    }
    return 0;
};

export function addComma(n) {
    var reg = /(^[+-]?\d+)(\d{3})/; // 정규식
    n += ""; // 숫자를 문자열로 변환

    while (reg.test(n)) n = n.replace(reg, "$1" + "," + "$2");

    return n;
}

export function removeComma(str) {
    var reg = /[,]/g;
    var val = str.replace(reg, "");

    if (isNaN(val)) return str;
    else return parseInt(val, 10);
}

export function getProjectStatusName(statusCd) {
    const statusCodes = {
        R: "접수", // Registered
        G: "견적중", // Estimating
        E: "견적", // Estimated
        C: "계약", // Contract
        I: "진행중", // In progress
        D: "개발완료", // Development complete
        P: "결제완료" // Payment complete
    };
    return statusCodes[statusCd] ? `${statusCodes[statusCd]}` : " - ";
}
export function getProjectIcon(statusCd) {
    const statusCodes = {
        R: receipt, // Registered
        G: estimate, // Estimating
        E: estimate, // Estimated
        C: contract, // Contract
        I: "", // In progress
        D: complete, // Development complete
        P: complete // Payment complete
    };
    return statusCodes[statusCd] ? `${statusCodes[statusCd]}` : " - ";
}

export function getProjectStatusList() {
    let statusList = [
        { code: "R", description: "접수" },
        { code: "G", description: "견적중" },
        { code: "E", description: "견적" },
        { code: "C", description: "계약" },
        { code: "I", description: "진행중" },
        { code: "D", description: "개발완료" },
        { code: "P", description: "결제완료" }
    ];
    return statusList;
}

export function getProjectStatusProgress(statusCd) {
    const statusPercents = {
        R: "",
        G: "10%",
        E: "20%",
        C: "30%",
        I: "60%",
        D: "90%",
        P: "100%"
    };
    return statusPercents[statusCd] ? `${statusPercents[statusCd]}` : " - ";
}
export function timeAgo(date) {
    const now = new Date();
    const timestamp = new Date(date);

    const seconds = Math.floor((now - timestamp) / 1000);

    if (seconds < 60) {
        return "방금 전";
    } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        return minutes + " 분" + (minutes > 1 ? "" : "") + " 전";
    } else if (seconds < 86400) {
        const hours = Math.floor(seconds / 3600);
        return hours + " 시간" + (hours > 1 ? "" : "") + " 전";
    } else if (seconds < 604800) {
        const days = Math.floor(seconds / 86400);
        return days + " 일" + (days > 1 ? "" : "") + " 전";
    } else if (seconds < 2419200) {
        const weeks = Math.floor(seconds / 604800);
        return weeks + " 주" + (weeks > 1 ? "" : "") + " 전";
    } else if (seconds < 29030400) {
        const months = Math.floor(seconds / 2419200);
        return months + " 월" + (months > 1 ? "" : "") + " 전";
    } else {
        const years = Math.floor(seconds / 29030400);
        return years + " 년" + (years > 1 ? "" : "") + " 전";
    }
}

export function parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
}
