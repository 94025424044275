import {
    MainConfig,
    AuthConfig,
    ProjectPaymentConfig,
    CommunityConfig,
    BannerConfig,
    ProjectConfig
} from 'views/pages';

import { CommonCodeFilterApi } from 'api/commonCode';
import { BannerApi } from 'api/main/banner';

export const Middlewares = [
    MainConfig.API.middleware,
    AuthConfig.API.middleware,
    CommunityConfig.API.middleware,
    ProjectConfig.API.middleware,
    ProjectPaymentConfig.API.middleware,
    CommonCodeFilterApi.middleware,
    BannerApi.middleware
];
