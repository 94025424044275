import React, { lazy } from 'react';
import Loadable from 'components/Loadable';
import { ProjectApi } from 'api/project/project';
import Pages from "store/pages";

import ContractHtml from "views/pages/project/contractHtml";
const MyProjects = Loadable(lazy(() => import("views/pages/project/list")));
const MyProject = Loadable(lazy(() => import("views/pages/project/project")));
const ViewItemsHtml = Loadable(lazy(() => import("views/pages/project/viewItemsHtml")));

const ProjectConfig = {
    routes: [
        {
            path: '',
            children: [
                { path: Pages.MY_PROJECT, element: <MyProjects /> },
                { path: Pages.MY_PROJECT_VIEW, element: <MyProject /> },
                { path: Pages.MY_PROJECT_CONTRACT, element: <MyProject /> },
                { path: Pages.MY_PROJECT_CONTRACT_PRINT, element: <ContractHtml /> },
                { path: Pages.MY_PROJECT_ITEMS_PRINT, element: <ViewItemsHtml /> },
            ]
        }
    ],
    API: ProjectApi
};

export default ProjectConfig;