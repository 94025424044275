import React, { lazy } from 'react';
import { AuthApi } from 'api/auth/auth';
import Loadable from 'components/Loadable';
import Pages from 'store/pages';

const LoginPage = Loadable(lazy(() => import('./login')));
const Register = Loadable(lazy(() => import('views/pages/auth/register')));
const FindId = Loadable(lazy(() => import('views/pages/auth/findId')));
const FindPassword = Loadable(lazy(() => import('views/pages/auth/findPassword')));
const Join = Loadable(lazy(() => import('views/pages/auth/join')));
const JoinComplete = Loadable(lazy(() => import('views/pages/auth/joinComplete')));

const AuthConfig = {
    routes: [
        {
            path: Pages.LOGIN,
            element: <LoginPage />
        },
        {
            path: Pages.JOIN,
            element: <Join />
        },
        {
            path: Pages.JOIN_COMPLETE,
            element: <JoinComplete />
        },
        {
            path: Pages.REGISTER,
            element: <Register />
        },
        {
            path: Pages.FIND_ID,
            element: <FindId />
        },
        {
            path: Pages.FIND_PASSWORD,
            element: <FindPassword />
        }
    ],
    API: AuthApi
};

export default AuthConfig;