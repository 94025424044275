import React, { lazy } from "react";
import Loadable from "components/Loadable";
import Pages from "store/pages";
import AuthConfig from "views/pages/auth/index";
import CommunityConfig from "views/pages/community";
import NotFound from "components/notFound";

const Main = Loadable(lazy(() => import("views/pages/main/main")));
const OldMain = Loadable(lazy(() => import("views/pages/main/Oldmain")));
const DynamicPage = Loadable(lazy(() => import("views/pages/dynamic/page")));
const ProjectRegister = Loadable(lazy(() => import("views/pages/project/projectRegister")));

const MainRoutes = [
    // MAIN
    { path: Pages.MAIN, element: <Main /> },

    // COSTUMER SERVICE
    { path: "", children: [...CommunityConfig.routes] },

    { path: "/web/menu/contents/:id", element: <DynamicPage /> },

    { path: Pages.PROJECT_CREATE, element: <ProjectRegister /> },

    // AUTH
    { path: "", children: [...AuthConfig.routes] },

    // 404 Not Found 페이지
    { path: "*", element: <NotFound /> }
];

export default MainRoutes;
